<template>

  <div class="view-trip">
    <main-nav />
    <main-titlebar myclass="purpleBar" showback="true"/>
    <div v-for="circle in circles" :key="circle.id" >

      <div class="trip-view-row2">
        <h2> {{circle.name}}</h2>
        <h2> Leden</h2>
      </div>
      <div v-for="member in circle.members" :key="member.id" class="trip-view-row2">
        <p> {{member.name}}</p>
      </div>

      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/my/profile/edit">
            <div class="default-button">Uitnodigen</div>
          </router-link>
        </div>
      </div>

    </div>
  </div>

</template>
<script>

import CircleService from "../../services/circle-service";

export default {
  name:'view-circle',
  data() {
    var status = 'loading'
    var circles;
    return { circles, status }
  },
  methods: {
    offerTrip(id) {
      this.$router.push('/trips/offer/'+id);    
    }
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    CircleService.getCircle(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;
        targ.circles = response.data;
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  computed: {
    isPublic() {
      return this.circles[0].id;
    },
  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>